import $api, { $newapi } from "../../../services/RequestService"
import { endpoints } from "../utils/constants/apis"

// counts
export const fetchCounts = async () => {
  const res = await $api.get(endpoints.COUNTS)
  return res.data
}

// top complexes
export const fetchTopComplexes = () => {
  const url = `/complex/top`

  return {
    fetch: async () => {
      const res = await $newapi.get(url)
      return res.data?.data ? res.data?.data : res.data
    },
    url,
  }
}

// apartment count
export const fetchApartmentCounts = async () => {
  const res = await $api.get(endpoints.APARTMENT_COUNTS)
  return res.data
}

// top builders
export const fetchTopBuilders = async () => {
  const res = await $api.get(endpoints.TOP_BUILDERS)
  return res.data
}

// top magazines
export const fetchTopMagazines = async (lang?: string) => {
  const res = await $newapi.get(`article/view?lang=${lang || "ru"}&top=true`)
  return res.data.data
}
