import Image from "next/image"
import Link from "next/link"
import React from "react"
import { MagazineType } from "../../pages/magazines/utils/models/magazineModel"
import {
  imageLayouts,
  imageLoadings,
  imageObjectFits,
} from "../../utils/constants/imageAttributes"
import { rootPaths } from "../../utils/constants/rootPaths"
import { makeUrl } from "../../utils/helpers/makeUrl"
import styles from "./MagazinesCont.module.scss"
import { useRouter } from "next/router"

type Props = {
  magazines: MagazineType[] | undefined
}

const MagazinesCont: React.FC<Props> = ({ magazines }) => {
  const { locale } = useRouter()

  return (
    <div className={styles.magazines_cont}>
      {magazines?.map((magazine, index) => (
        <Link
          href={`${rootPaths.MAGAZINES}/${makeUrl(
            magazine.slug ? magazine.slug : magazine.name
          )}-${magazine.id}`}
          key={index}
          legacyBehavior
        >
          <a className={styles.magazine}>
            <div>
              <div className={styles.tags}>
                <span>
                  {
                    magazine.category?.name[
                      locale as keyof typeof magazine.category.name
                    ]
                  }
                </span>
              </div>
              <div className={styles.image}>
                <Image
                  src={magazine.image}
                  alt={magazine.name}
                  layout={imageLayouts.FILL}
                  objectFit={imageObjectFits.COVER}
                  loading={imageLoadings.LAZY}
                  // width={"100%"}
                  // height={"100%"}
                />
              </div>
              <div className={styles.more_info}>
                <h3 className={styles.title}>{magazine.name}</h3>
                <span className={styles.date}>{magazine.created_time}</span>
              </div>
            </div>
          </a>
        </Link>
      ))}
    </div>
  )
}

export default MagazinesCont
