import React from "react"

import MainMagazines from "./components/magazines/MainMagazines"
import MainNewComplexes from "./components/new-complexes/NewComplexes"
import MainTopBuilders from "./components/top-builders/MainTopBuilders"
import MainTop from "./components/top/MainTop"
import MainUsefulLinks from "./components/useful-links/MainUsefulLinks"
import SeoText from "./components/seo-text/SeoText"

const Index: React.FC = () => {
  return (
    <>
      <MainTop />
      <MainNewComplexes />
      <MainUsefulLinks />
      <MainTopBuilders />
      <MainMagazines />
      <SeoText />
    </>
  )
}

export default Index
